import { Icon } from '@chakra-ui/react';
import { FC } from 'react';

interface AnotoysCollectiverseProps {
    width: string
    height: string
    fill: string
}

export const AnotoysCollectiverse: FC<AnotoysCollectiverseProps> = (props) => {

    const { width, height, fill } = props;

  return (
    // <Icon viewBox="0 0 167 41" w={width} h={height}>
    //     <svg width="167" height="41" viewBox="0 0 167 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    //         <path d="M46.2416 14.2044V2.24146H52.3372V25.0589H47.1543L38.0927 12.6723V25.0589H31.9971V2.24146H37.4733L46.2416 14.2044Z" fill={fill}/>
    //         <path d="M71.2102 18.9961C72.1229 18.4745 72.8344 17.7467 73.3453 16.8121C73.8557 15.878 74.1113 14.8237 74.1113 13.6503C74.1113 12.4768 73.8557 11.4231 73.3453 10.4884C72.8344 9.55435 72.1229 8.82598 71.2102 8.30446C70.2976 7.78293 69.2759 7.52217 68.1463 7.52217C66.9941 7.52217 65.9623 7.78293 65.0494 8.30446C64.1368 8.82598 63.4197 9.55435 62.8982 10.4884C62.3766 11.4231 62.1159 12.4768 62.1159 13.6503C62.1159 14.8237 62.3766 15.878 62.8982 16.8121C63.4197 17.7467 64.1368 18.4745 65.0494 18.9961C65.9623 19.5178 66.9941 19.7783 68.1463 19.7783C69.2759 19.7783 70.2976 19.5178 71.2102 18.9961ZM61.6596 24.0975C59.747 23.0656 58.2532 21.6419 57.1775 19.8273C56.1019 18.013 55.564 15.9539 55.564 13.6503C55.564 11.3472 56.1019 9.28805 57.1775 7.47342C58.2532 5.65918 59.747 4.2357 61.6596 3.20317C63.5715 2.1714 65.734 1.65485 68.1463 1.65485C70.5583 1.65485 72.7148 2.16566 74.6166 3.18692C76.5178 4.20855 78.0009 5.63165 79.0659 7.45698C80.1304 9.28231 80.6632 11.3472 80.6632 13.6503C80.6632 15.9539 80.1304 18.0182 79.0659 19.8437C78.0009 21.6691 76.5178 23.0925 74.6166 24.1138C72.7148 25.1349 70.5583 25.6459 68.1463 25.6459C65.734 25.6459 63.5715 25.1293 61.6596 24.0975Z" fill={fill}/>
    //         <path d="M81.707 2.24146H100.417V7.91321H94.1915V25.0589H87.9003V7.91321H81.707V2.24146Z" fill={fill}/>
    //         <path d="M117.074 18.9961C117.986 18.4745 118.698 17.7467 119.209 16.8121C119.719 15.878 119.975 14.8237 119.975 13.6503C119.975 12.4768 119.719 11.4231 119.209 10.4884C118.698 9.55435 117.986 8.82598 117.074 8.30446C116.161 7.78293 115.139 7.52217 114.01 7.52217C112.857 7.52217 111.826 7.78293 110.913 8.30446C110 8.82598 109.283 9.55435 108.761 10.4884C108.24 11.4231 107.979 12.4768 107.979 13.6503C107.979 14.8237 108.24 15.878 108.761 16.8121C109.283 17.7467 110 18.4745 110.913 18.9961C111.826 19.5178 112.857 19.7783 114.01 19.7783C115.139 19.7783 116.161 19.5178 117.074 18.9961ZM107.523 24.0975C105.61 23.0656 104.116 21.6419 103.041 19.8273C101.965 18.013 101.427 15.9539 101.427 13.6503C101.427 11.3472 101.965 9.28805 103.041 7.47342C104.116 5.65918 105.61 4.2357 107.523 3.20317C109.435 2.1714 111.597 1.65485 114.01 1.65485C116.422 1.65485 118.578 2.16566 120.48 3.18692C122.381 4.20855 123.864 5.63165 124.929 7.45698C125.994 9.28231 126.526 11.3472 126.526 13.6503C126.526 15.9539 125.994 18.0182 124.929 19.8437C123.864 21.6691 122.381 23.0925 120.48 24.1138C118.578 25.1349 116.422 25.6459 114.01 25.6459C111.597 25.6459 109.435 25.1293 107.523 24.0975Z" fill={fill}/>
    //         <path d="M141.195 2.24146H148.171L140.185 16.1274V25.0589H133.796V16.1274L125.875 2.24146H132.851L137.023 10.4883L141.195 2.24146Z" fill={fill}/>
    //         <path d="M150.876 23.5101C149.224 22.1084 148.398 20.169 148.398 17.6917H154.885C154.885 18.474 155.14 19.072 155.651 19.4844C156.161 19.8975 156.797 20.1038 157.558 20.1038C158.275 20.1038 158.883 19.9302 159.383 19.5823C159.883 19.2349 160.133 18.7673 160.133 18.1808C160.133 17.5287 159.823 17.0509 159.204 16.7464C158.585 16.4424 157.579 16.127 156.189 15.801C154.646 15.4099 153.374 15.008 152.375 14.5951C151.375 14.1824 150.511 13.5087 149.784 12.574C149.055 11.6399 148.692 10.3686 148.692 8.76027C148.692 6.52239 149.458 4.78347 150.99 3.54485C152.522 2.30623 154.646 1.68683 157.362 1.68683C160.079 1.68683 162.192 2.35001 163.702 3.67523C165.212 5.00102 165.968 6.82636 165.968 9.15142H159.677C159.677 8.45612 159.454 7.92905 159.008 7.5706C158.563 7.21195 157.992 7.03263 157.297 7.03263C156.689 7.03263 156.167 7.16875 155.732 7.44002C155.298 7.71207 155.081 8.09766 155.081 8.59739C155.081 9.16269 155.385 9.5915 155.993 9.88495C156.601 10.1782 157.59 10.499 158.959 10.8464C160.546 11.2595 161.855 11.6833 162.887 12.1177C163.919 12.5528 164.816 13.2479 165.577 14.204C166.337 15.1604 166.718 16.4424 166.718 18.0502C166.718 20.4624 165.881 22.326 164.208 23.6405C162.534 24.9556 160.329 25.6127 157.59 25.6127C154.765 25.6127 152.527 24.9118 150.876 23.5101Z" fill={fill}/>
    //         <path d="M0.70983 40.2077C0.236482 39.7127 0 39.0138 0 38.1107V34.3594C0 33.4475 0.23438 32.7442 0.70333 32.2493C1.17228 31.7543 1.81482 31.5067 2.63113 31.5067C3.46465 31.5067 4.11598 31.7478 4.58493 32.2298C5.05369 32.7117 5.30547 33.4043 5.34026 34.3073H4.03779C4.003 33.7777 3.86841 33.3869 3.63403 33.135C3.39946 32.8832 3.06089 32.7572 2.61794 32.7572C2.21858 32.7572 1.90601 32.892 1.68023 33.161C1.45445 33.4303 1.34166 33.8299 1.34166 34.3594V38.1107C1.34166 38.6404 1.45656 39.0377 1.68673 39.3024C1.91671 39.5674 2.23578 39.6997 2.64413 39.6997C3.08708 39.6997 3.42756 39.5739 3.66653 39.3219C3.90531 39.0702 4.04639 38.6836 4.08998 38.1627H5.39245C5.34886 39.0572 5.09059 39.7452 4.61743 40.2272C4.14408 40.7091 3.48625 40.95 2.64413 40.95C1.82782 40.95 1.18299 40.7026 0.70983 40.2077Z" fill={fill}/>
    //         <path d="M17.8178 39.2181C18.0608 38.9143 18.1826 38.4758 18.1826 37.9026V34.5682C18.1826 33.995 18.0608 33.5567 17.8178 33.2525C17.5746 32.9487 17.2187 32.7968 16.7497 32.7968C16.2808 32.7968 15.9248 32.9487 15.6816 33.2525C15.4385 33.5567 15.3169 33.995 15.3169 34.5682V37.9026C15.3169 38.4758 15.4385 38.9143 15.6816 39.2181C15.9248 39.5223 16.2808 39.6741 16.7497 39.6741C17.2187 39.6741 17.5746 39.5223 17.8178 39.2181ZM14.7047 40.1495C14.2098 39.6068 13.9624 38.8579 13.9624 37.9026V34.5682C13.9624 33.6131 14.2098 32.8641 14.7047 32.3213C15.1999 31.7788 15.8814 31.5073 16.7497 31.5073C17.618 31.5073 18.2998 31.7788 18.7947 32.3213C19.2897 32.8641 19.5372 33.6131 19.5372 34.5682V37.9026C19.5372 38.8579 19.2897 39.6068 18.7947 40.1495C18.2998 40.6923 17.618 40.9635 16.7497 40.9635C15.8814 40.9635 15.1999 40.6923 14.7047 40.1495Z" fill={fill}/>
    //         <path d="M32.9921 39.5433V40.7938H28.6548V31.6761H29.9703V39.5433H32.9921Z" fill={fill}/>
    //         <path d="M45.8881 39.5433V40.7938H41.5508V31.6761H42.8662V39.5433H45.8881Z" fill={fill}/>
    //         <path d="M59.0433 39.5563V40.7938H54.4453V31.6761H58.9131V32.9134H55.761V35.5576H58.6655V36.769H55.761V39.5563H59.0433Z" fill={fill}/>
    //         <path d="M68.4525 40.2077C67.9792 39.7127 67.7427 39.0138 67.7427 38.1107V34.3594C67.7427 33.4475 67.9771 32.7442 68.446 32.2493C68.915 31.7543 69.5575 31.5067 70.3738 31.5067C71.2073 31.5067 71.8587 31.7478 72.3276 32.2298C72.7964 32.7117 73.0481 33.4043 73.0829 34.3073H71.7805C71.7457 33.7777 71.6111 33.3869 71.3767 33.135C71.1421 32.8832 70.8036 32.7572 70.3606 32.7572C69.9613 32.7572 69.6487 32.892 69.4229 33.161C69.1971 33.4303 69.0843 33.8299 69.0843 34.3594V38.1107C69.0843 38.6404 69.1992 39.0377 69.4294 39.3024C69.6594 39.5674 69.9785 39.6997 70.3868 39.6997C70.8298 39.6997 71.1702 39.5739 71.4092 39.3219C71.648 39.0702 71.7891 38.6836 71.8327 38.1627H73.1351C73.0915 39.0572 72.8333 39.7452 72.3601 40.2272C71.8868 40.7091 71.2289 40.95 70.3868 40.95C69.5705 40.95 68.9257 40.7026 68.4525 40.2077Z" fill={fill}/>
    //         <path d="M86.3941 32.9266H84.4925V40.7938H83.1508V32.9266H81.2622V31.6761H86.3941V32.9266Z" fill={fill}/>
    //         <path d="M96.3199 31.6761H95.0044V40.7938H96.3199V31.6761Z" fill={fill}/>
    //         <path d="M104.866 31.6761H106.247L107.341 36.7038C107.445 37.1816 107.567 37.8285 107.706 38.6446C107.732 38.7576 107.762 38.9182 107.797 39.1266H107.849C108.048 38.0063 108.201 37.1988 108.305 36.7038L109.412 31.6761H110.78L108.539 40.7938H107.107L104.866 31.6761Z" fill={fill}/>
    //         <path d="M123.946 39.5563V40.7938H119.348V31.6761H123.816V32.9134H120.664V35.5576H123.568V36.769H120.664V39.5563H123.946Z" fill={fill}/>
    //         <path d="M134.223 36.2351H135.239C135.699 36.2351 136.049 36.0917 136.288 35.8051C136.526 35.5186 136.646 35.1018 136.646 34.5548C136.646 34.0165 136.529 33.6104 136.294 33.3369C136.06 33.0635 135.712 32.9266 135.252 32.9266H134.223V36.2351ZM135.044 37.4855H134.223V40.7938H132.908V31.6761H135.265C136.133 31.6761 136.807 31.926 137.284 32.4252C137.762 32.9247 138.001 33.6257 138.001 34.5288C138.001 35.232 137.857 35.8227 137.571 36.3001C137.284 36.7778 136.88 37.1122 136.359 37.3032L138.339 40.7938H136.88L135.044 37.4855Z" fill={fill}/>
    //         <path d="M147.144 40.2472C146.667 39.7696 146.423 39.1185 146.415 38.2934H147.757C147.765 38.7537 147.885 39.1009 148.115 39.3353C148.345 39.5699 148.668 39.6871 149.085 39.6871C149.51 39.6871 149.834 39.5743 150.056 39.3485C150.277 39.1227 150.388 38.8189 150.388 38.4366C150.388 38.072 150.253 37.7616 149.984 37.5054C149.715 37.2494 149.307 36.9605 148.759 36.6392C148.282 36.344 147.9 36.0836 147.613 35.8577C147.327 35.6319 147.081 35.3497 146.877 35.0109C146.673 34.6724 146.571 34.273 146.571 33.8127C146.571 33.3787 146.673 32.9856 146.877 32.6339C147.081 32.2823 147.372 32.0068 147.75 31.8069C148.128 31.6073 148.568 31.5073 149.072 31.5073C149.845 31.5073 150.457 31.7375 150.909 32.1976C151.36 32.658 151.595 33.2787 151.612 34.0602H150.27C150.262 33.6349 150.153 33.3135 149.945 33.0963C149.736 32.8793 149.45 32.7708 149.085 32.7708C148.72 32.7708 148.434 32.8663 148.225 33.0573C148.017 33.2483 147.913 33.5089 147.913 33.8389C147.913 34.0906 147.982 34.3122 148.121 34.5032C148.26 34.6942 148.432 34.8613 148.636 35.0046C148.84 35.1478 149.128 35.3325 149.502 35.5581C149.988 35.8447 150.379 36.101 150.674 36.3266C150.969 36.5524 151.221 36.841 151.43 37.1928C151.638 37.5444 151.742 37.9634 151.742 38.4498C151.742 38.9099 151.638 39.3311 151.43 39.7132C151.221 40.0954 150.915 40.3992 150.511 40.625C150.108 40.8505 149.632 40.9635 149.085 40.9635C148.269 40.9635 147.622 40.7249 147.144 40.2472Z" fill={fill}/>
    //         <path d="M165.08 39.5563V40.7938H160.482V31.6761H164.95V32.9134H161.798V35.5576H164.702V36.769H161.798V39.5563H165.08Z" fill={fill}/>
    //         <path d="M12.7373 0.787043L9.70111 6.05354C9.01657 7.78696 11.3904 8.51566 11.3904 8.51566C11.3904 8.51566 18.0259 10.8122 19.671 11.2869C21.3161 11.7617 20.3224 9.71911 20.3224 9.71911C20.3224 9.71911 17.0212 4.11035 15.4755 1.38325C14.8682 0.323326 14.3162 0.00314057 13.8746 0.00314057C13.179 0.00314057 12.7373 0.787043 12.7373 0.787043Z" fill="url(#paint0_linear_970_21613)"/>
    //         <path d="M12.7323 0.7945L10.1562 5.28045C14.0564 1.98219 18.1812 6.06689 18.1812 6.06689L15.4768 1.38835C14.8749 0.329053 14.3212 3.02891e-05 13.8718 3.02891e-05C13.1736 3.02891e-05 12.7323 0.7945 12.7323 0.7945Z" fill="url(#paint1_linear_970_21613)"/>
    //         <path d="M6.547 12.0417C6.547 12.0417 1.42052 20.6997 0.423706 22.4228C-0.573109 24.1316 2.50278 24.2455 3.89832 24.2455C6.93149 24.3167 8.15614 22.4228 9.26688 20.8421C10.3776 19.2757 12.0864 19.0478 12.9409 19.0478C15.1338 19.2045 17.4977 20.8848 18.523 21.8104C21.2856 24.3167 23.5071 24.2455 23.5071 24.2455H26.5118C28.5909 24.274 27.6937 22.0952 27.6937 22.0952C27.6937 22.0952 26.4406 20.0874 25.7286 18.6634C23.3647 13.9356 20.4597 13.3945 20.4597 13.3945L10.2922 10.0765C10.0643 10.0196 9.83649 10.0053 9.62289 10.0053C7.51533 10.0053 6.547 12.0417 6.547 12.0417Z" fill="url(#paint2_linear_970_21613)"/>
    //         <path d="M6.54088 12.0459C6.54088 12.0459 1.41398 20.6989 0.418881 22.4079C-0.576214 24.1385 2.4956 24.2467 3.90171 24.2467C6.93026 24.3116 8.24985 22.4944 9.26657 20.8504C14.1555 11.1806 21.8567 14.0361 21.8567 14.0361C21.2942 13.5818 20.4506 13.4088 20.4506 13.4088L10.3049 10.0774C10.067 10.0341 9.82902 10.0125 9.61269 10.0125C7.51434 10.0125 6.54088 12.0459 6.54088 12.0459Z" fill="url(#paint3_linear_970_21613)"/>
    //         <defs>
    //         <linearGradient id="paint0_linear_970_21613" x1="9.57966" y1="5.68156" x2="20.6205" y2="5.68156" gradientUnits="userSpaceOnUse">
    //         <stop stopColor="#BE06BA"/>
    //         <stop offset="1" stopColor="#FF4F42"/>
    //         </linearGradient>
    //         <linearGradient id="paint1_linear_970_21613" x1="10.1562" y1="3.03344" x2="18.1812" y2="3.03344" gradientUnits="userSpaceOnUse">
    //         <stop stopColor="#02C6D6"/>
    //         <stop offset="0.99" stopColor="#FF257D"/>
    //         <stop offset="1" stopColor="#FF257D"/>
    //         </linearGradient>
    //         <linearGradient id="paint2_linear_970_21613" x1="14.0541" y1="10.0053" x2="14.0541" y2="24.2455" gradientUnits="userSpaceOnUse">
    //         <stop stopColor="#BE06BA"/>
    //         <stop offset="1" stopColor="#FF4F42"/>
    //         </linearGradient>
    //         <linearGradient id="paint3_linear_970_21613" x1="0.224189" y1="17.1249" x2="21.8567" y2="17.1249" gradientUnits="userSpaceOnUse">
    //         <stop stopColor="#02C6D6"/>
    //         <stop offset="0.99" stopColor="#FF257D"/>
    //         <stop offset="1" stopColor="#FF257D"/>
    //         </linearGradient>
    //         </defs>
    //     </svg>
    // </Icon>

    <Icon viewBox="0 0 180 34" w={width} h={height}>
      <svg width="180" height="34" viewBox="0 0 180 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3712_38484)">
        <path d="M77.6086 10.6981V0.522949H82.8873V19.9306H78.3991L70.5521 9.39502V19.9306H65.2734V0.522949H70.0157L77.6086 10.6981Z" fill={fill}/>
        <path d="M98.8307 14.7666C99.6197 14.3225 100.235 13.7027 100.677 12.9068C101.118 12.1114 101.339 11.2137 101.339 10.2145C101.339 9.21525 101.118 8.31794 100.677 7.52206C100.235 6.72667 99.6197 6.10642 98.8307 5.66234C98.0417 5.21826 97.1585 4.9962 96.1817 4.9962C95.1856 4.9962 94.2937 5.21826 93.5045 5.66234C92.7155 6.10642 92.0954 6.72667 91.6444 7.52206C91.1938 8.31794 90.9683 9.21525 90.9683 10.2145C90.9683 11.2137 91.1938 12.1114 91.6444 12.9068C92.0954 13.7027 92.7155 14.3225 93.5045 14.7666C94.2937 15.2108 95.1856 15.4327 96.1817 15.4327C97.1585 15.4327 98.0417 15.2108 98.8307 14.7666ZM90.5737 19.1106C88.9202 18.2319 87.6286 17.0196 86.6986 15.4744C85.7688 13.9295 85.3037 12.1761 85.3037 10.2145C85.3037 8.25332 85.7688 6.49991 86.6986 4.9547C87.6286 3.40981 88.9202 2.19767 90.5737 1.31844C92.2267 0.43986 94.0962 0 96.1817 0C98.2672 0 100.131 0.434977 101.776 1.3046C103.419 2.17455 104.702 3.38637 105.622 4.9407C106.543 6.49502 107.003 8.25332 107.003 10.2145C107.003 12.1761 106.543 13.9339 105.622 15.4884C104.702 17.0427 103.419 18.2548 101.776 19.1245C100.131 19.9939 98.2672 20.4291 96.1817 20.4291C94.0962 20.4291 92.2267 19.9892 90.5737 19.1106Z" fill={fill}/>
        <path d="M107.477 0.54834H123.646V5.37251H118.266V19.956H112.829V5.37251H107.477V0.54834Z" fill={fill}/>
        <path d="M137.761 14.7666C138.551 14.3225 139.167 13.7027 139.609 12.9068C140.051 12.1114 140.272 11.2137 140.272 10.2145C140.272 9.21525 140.051 8.31794 139.609 7.52206C139.167 6.72667 138.551 6.10642 137.761 5.66234C136.971 5.21826 136.087 4.9962 135.109 4.9962C134.112 4.9962 133.219 5.21826 132.429 5.66234C131.639 6.10642 131.018 6.72667 130.567 7.52206C130.116 8.31794 129.89 9.21525 129.89 10.2145C129.89 11.2137 130.116 12.1114 130.567 12.9068C131.018 13.7027 131.639 14.3225 132.429 14.7666C133.219 15.2108 134.112 15.4327 135.109 15.4327C136.087 15.4327 136.971 15.2108 137.761 14.7666ZM129.495 19.1106C127.839 18.2319 126.546 17.0196 125.615 15.4744C124.684 13.9295 124.219 12.1761 124.219 10.2145C124.219 8.25332 124.684 6.49991 125.615 4.9547C126.546 3.40981 127.839 2.19767 129.495 1.31844C131.15 0.43986 133.021 0 135.109 0C137.197 0 139.063 0.434977 140.71 1.3046C142.355 2.17455 143.639 3.38637 144.561 4.9407C145.482 6.49502 145.943 8.25332 145.943 10.2145C145.943 12.1761 145.482 13.9339 144.561 15.4884C143.639 17.0427 142.355 18.2548 140.71 19.1245C139.063 19.9939 137.197 20.4291 135.109 20.4291C133.021 20.4291 131.15 19.9892 129.495 19.1106Z" fill={fill}/>
        <path d="M158.138 0.522949H164.155L157.266 12.3338V19.9306H151.755V12.3338L144.922 0.522949H150.939L154.538 7.53742L158.138 0.522949Z" fill={fill}/>
        <path d="M166.298 18.6133C164.869 17.4194 164.155 15.7674 164.155 13.6574H169.766C169.766 14.3237 169.986 14.833 170.428 15.1843C170.869 15.5362 171.419 15.7119 172.078 15.7119C172.698 15.7119 173.224 15.564 173.656 15.2677C174.088 14.9718 174.305 14.5735 174.305 14.0739C174.305 13.5185 174.037 13.1115 173.501 12.8521C172.966 12.5932 172.096 12.3245 170.893 12.0469C169.559 11.7137 168.459 11.3715 167.595 11.0198C166.73 10.6682 165.983 10.0943 165.353 9.29825C164.723 8.50262 164.409 7.41978 164.409 6.04983C164.409 4.14368 165.071 2.66252 166.397 1.6075C167.721 0.552491 169.559 0.0249023 171.908 0.0249023C174.258 0.0249023 176.085 0.589781 177.392 1.71856C178.698 2.84783 179.352 4.40258 179.352 6.383H173.91C173.91 5.79076 173.717 5.34182 173.332 5.0365C172.946 4.73101 172.453 4.57829 171.852 4.57829C171.325 4.57829 170.874 4.69421 170.499 4.92529C170.122 5.15701 169.935 5.48544 169.935 5.91109C169.935 6.39259 170.198 6.75785 170.724 7.00781C171.25 7.25759 172.106 7.53082 173.29 7.82669C174.662 8.17859 175.794 8.53959 176.687 8.90955C177.58 9.28017 178.355 9.87224 179.013 10.6866C179.671 11.5013 180 12.5932 180 13.9627C180 16.0174 179.276 17.6047 177.829 18.7243C176.381 19.8445 174.474 20.4041 172.106 20.4041C169.662 20.4041 167.726 19.8072 166.298 18.6133Z" fill={fill}/>
        <path d="M43.1407 32.851C42.7316 32.4292 42.5273 31.8336 42.5273 31.064V27.8673C42.5273 27.0903 42.7299 26.4911 43.135 26.0693C43.5401 25.6475 44.0951 25.4365 44.8005 25.4365C45.5207 25.4365 46.0832 25.6421 46.4886 26.0526C46.8934 26.4635 47.1109 27.0537 47.1411 27.823H46.0157C45.9858 27.3718 45.8695 27.0387 45.6669 26.824C45.4644 26.6094 45.1719 26.5021 44.7892 26.5021C44.4442 26.5021 44.1741 26.6169 43.9791 26.8461C43.784 27.0758 43.6866 27.4161 43.6866 27.8673V31.064C43.6866 31.5154 43.7857 31.854 43.9845 32.0797C44.1833 32.3054 44.4589 32.418 44.8117 32.418C45.1943 32.418 45.4886 32.3109 45.6951 32.0962C45.9014 31.8816 46.0232 31.5523 46.0608 31.1083H47.1862C47.1485 31.8707 46.9253 32.4569 46.5165 32.8677C46.1077 33.2783 45.5394 33.4836 44.8117 33.4836C44.1066 33.4836 43.5493 33.2728 43.1407 32.851Z" fill={fill}/>
        <path d="M57.3626 31.9982C57.5723 31.7396 57.6772 31.3667 57.6772 30.8789V28.0412C57.6772 27.5534 57.5723 27.1805 57.3626 26.9216C57.153 26.6633 56.8459 26.5337 56.4415 26.5337C56.0369 26.5337 55.73 26.6633 55.5202 26.9216C55.3104 27.1805 55.2055 27.5534 55.2055 28.0412V30.8789C55.2055 31.3667 55.3104 31.7396 55.5202 31.9982C55.73 32.2571 56.0369 32.3864 56.4415 32.3864C56.8459 32.3864 57.153 32.2571 57.3626 31.9982ZM54.6776 32.791C54.2506 32.3291 54.0374 31.6918 54.0374 30.8789V28.0412C54.0374 27.2286 54.2506 26.591 54.6776 26.1291C55.1046 25.6675 55.6926 25.4365 56.4415 25.4365C57.1904 25.4365 57.7784 25.6675 58.2051 26.1291C58.6322 26.591 58.8457 27.2286 58.8457 28.0412V30.8789C58.8457 31.6918 58.6322 32.3291 58.2051 32.791C57.7784 33.2526 57.1904 33.4836 56.4415 33.4836C55.6926 33.4836 55.1046 33.2526 54.6776 32.791Z" fill={fill}/>
        <path d="M70.0317 32.268V33.334H66.2449V25.561H67.3934V32.268H70.0317Z" fill={fill}/>
        <path d="M80.6699 32.268V33.334H76.8831V25.561H78.0316V32.268H80.6699Z" fill={fill}/>
        <path d="M91.5321 32.279V33.334H87.5459V25.561H91.4192V26.6159H88.6864V28.87H91.2044V29.9027H88.6864V32.279H91.5321Z" fill={fill}/>
        <path d="M99.0746 32.851C98.6633 32.4292 98.458 31.8336 98.458 31.064V27.8673C98.458 27.0903 98.6616 26.4911 99.0689 26.0693C99.4762 25.6475 100.034 25.4365 100.743 25.4365C101.467 25.4365 102.033 25.6421 102.44 26.0526C102.848 26.4635 103.066 27.0537 103.096 27.823H101.965C101.935 27.3718 101.818 27.0387 101.615 26.824C101.411 26.6094 101.117 26.5021 100.732 26.5021C100.385 26.5021 100.114 26.6169 99.9174 26.8461C99.7214 27.0758 99.6235 27.4161 99.6235 27.8673V31.064C99.6235 31.5154 99.7231 31.854 99.9232 32.0797C100.123 32.3054 100.4 32.418 100.755 32.418C101.139 32.418 101.435 32.3109 101.643 32.0962C101.85 31.8816 101.973 31.5523 102.01 31.1083H103.142C103.104 31.8707 102.88 32.4569 102.469 32.8677C102.058 33.2783 101.486 33.4836 100.755 33.4836C100.045 33.4836 99.4854 33.2728 99.0746 32.851Z" fill={fill}/>
        <path d="M114.029 26.6271H112.395V33.334H111.242V26.6271H109.619V25.561H114.029V26.6271Z" fill={fill}/>
        <path d="M122.151 25.561H121.055V33.334H122.151V25.561Z" fill={fill}/>
        <path d="M129.077 25.561H130.275L131.225 29.8472C131.315 30.2545 131.421 30.8061 131.541 31.5019C131.564 31.5981 131.59 31.7351 131.62 31.9127H131.666C131.839 30.9575 131.971 30.2692 132.061 29.8472L133.022 25.561H134.209L132.265 33.334H131.021L129.077 25.561Z" fill={fill}/>
        <path d="M145.096 32.279V33.334H141.135V25.561H144.984V26.6159H142.269V28.87H144.771V29.9027H142.269V32.279H145.096Z" fill={fill}/>
        <path d="M153.468 29.4475H154.353C154.754 29.4475 155.059 29.3255 155.267 29.0811C155.475 28.8367 155.579 28.4814 155.579 28.0153C155.579 27.5564 155.477 27.2101 155.272 26.9769C155.068 26.7437 154.765 26.6271 154.364 26.6271H153.468V29.4475ZM154.183 30.5136H153.468V33.334H152.321V25.561H154.376C155.132 25.561 155.719 25.774 156.135 26.1996C156.551 26.6253 156.76 27.223 156.76 27.9931C156.76 28.5925 156.635 29.096 156.385 29.5031C156.135 29.9104 155.783 30.1954 155.329 30.3581L157.055 33.334H155.783L154.183 30.5136Z" fill={fill}/>
        <path d="M164.138 32.8739C163.725 32.4676 163.515 31.9135 163.507 31.2115H164.668C164.675 31.6031 164.779 31.8986 164.978 32.0979C165.177 32.2977 165.457 32.3974 165.818 32.3974C166.185 32.3974 166.465 32.3014 166.657 32.1091C166.848 31.917 166.944 31.6587 166.944 31.3333C166.944 31.0229 166.828 30.7588 166.595 30.5408C166.362 30.3231 166.009 30.0772 165.536 29.8036C165.122 29.5525 164.792 29.331 164.544 29.1387C164.296 28.9463 164.084 28.7064 163.907 28.4182C163.731 28.1299 163.642 27.7901 163.642 27.3985C163.642 27.029 163.731 26.6947 163.907 26.3952C164.084 26.096 164.335 25.8615 164.662 25.6914C164.989 25.5217 165.37 25.4365 165.806 25.4365C166.475 25.4365 167.004 25.6323 167.395 26.024C167.785 26.4156 167.989 26.944 168.003 27.609H166.843C166.835 27.247 166.741 26.9737 166.561 26.7888C166.381 26.6042 166.133 26.5118 165.818 26.5118C165.502 26.5118 165.254 26.593 165.074 26.7557C164.893 26.9181 164.803 27.1399 164.803 27.4206C164.803 27.6349 164.863 27.8235 164.983 27.9859C165.104 28.1486 165.252 28.2906 165.429 28.4127C165.605 28.5345 165.855 28.6917 166.178 28.8836C166.599 29.1275 166.937 29.3457 167.192 29.5375C167.447 29.7299 167.665 29.9755 167.846 30.2747C168.026 30.5739 168.116 30.9304 168.116 31.3445C168.116 31.7359 168.026 32.0944 167.846 32.4195C167.665 32.7449 167.401 33.0032 167.051 33.1956C166.702 33.3874 166.291 33.4836 165.818 33.4836C165.111 33.4836 164.551 33.2805 164.138 32.8739Z" fill={fill}/>
        <path d="M179.078 32.279V33.334H175.117V25.561H178.966V26.6159H176.251V28.87H178.753V29.9027H176.251V32.279H179.078Z" fill={fill}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.78 19.9378H48.5208L49.6023 16.8039H56.4802L57.5341 19.9378H63.4413L55.6483 0.524414H50.4065L42.78 19.9378ZM50.9058 12.6162H55.1767L53.0135 6.59805L50.9058 12.6162Z" fill={fill}/>
        <path d="M17.2587 1.62267L13.0713 8.77473C12.1273 11.1288 15.401 12.1183 15.401 12.1183C15.401 12.1183 24.5523 15.2371 26.821 15.8818C29.0899 16.5265 27.7194 13.7527 27.7194 13.7527C27.7194 13.7527 23.1666 6.13582 21.0349 2.43234C20.1974 0.992926 19.4361 0.558105 18.827 0.558105C17.8678 0.558105 17.2587 1.62267 17.2587 1.62267Z" fill="url(#paint0_linear_3712_38484)"/>
        <path d="M17.2491 1.63262L13.6965 7.72468C19.0753 3.24555 24.7639 8.79269 24.7639 8.79269L21.0342 2.43908C20.2041 1.00053 19.4405 0.553711 18.8207 0.553711C17.8579 0.553711 17.2491 1.63262 17.2491 1.63262Z" fill="url(#paint1_linear_3712_38484)"/>
        <path d="M8.71982 16.9065C8.71982 16.9065 1.64978 28.6644 0.275051 31.0043C-1.09968 33.325 3.14233 33.4797 5.06695 33.4797C9.25005 33.5763 10.939 31.0043 12.4708 28.8577C14.0027 26.7306 16.3594 26.4212 17.5377 26.4212C20.5621 26.6339 23.8222 28.9158 25.2361 30.1729C29.0462 33.5763 32.1098 33.4797 32.1098 33.4797H36.2536C39.1209 33.5183 37.8837 30.5596 37.8837 30.5596C37.8837 30.5596 36.1555 27.8328 35.1734 25.899C31.9135 19.4786 27.9071 18.7437 27.9071 18.7437L13.8849 14.2378C13.5706 14.1604 13.2564 14.1411 12.9618 14.1411C10.0553 14.1411 8.71982 16.9065 8.71982 16.9065Z" fill="url(#paint2_linear_3712_38484)"/>
        <path d="M8.71158 16.9124C8.71158 16.9124 1.64095 28.6633 0.268597 30.9843C-1.10376 33.3344 3.13265 33.4814 5.07184 33.4814C9.24859 33.5696 11.0684 31.1016 12.4706 28.8691C19.2131 15.7373 29.8339 19.6151 29.8339 19.6151C29.0581 18.9982 27.8946 18.7632 27.8946 18.7632L13.9027 14.239C13.5745 14.1803 13.2463 14.1509 12.948 14.1509C10.0541 14.1509 8.71158 16.9124 8.71158 16.9124Z" fill="url(#paint3_linear_3712_38484)"/>
        </g>
        <defs>
        <linearGradient id="paint0_linear_3712_38484" x1="12.9038" y1="8.26956" x2="28.1305" y2="8.26956" gradientUnits="userSpaceOnUse">
        <stop stopColor="#BE06BA"/>
        <stop offset="1" stopColor="#FF4F42"/>
        </linearGradient>
        <linearGradient id="paint1_linear_3712_38484" x1="13.6965" y1="4.67317" x2="24.7639" y2="4.67317" gradientUnits="userSpaceOnUse">
        <stop stopColor="#02C6D6"/>
        <stop offset="0.99" stopColor="#FF257D"/>
        <stop offset="1" stopColor="#FF257D"/>
        </linearGradient>
        <linearGradient id="paint2_linear_3712_38484" x1="19.073" y1="14.1411" x2="19.073" y2="33.4797" gradientUnits="userSpaceOnUse">
        <stop stopColor="#BE06BA"/>
        <stop offset="1" stopColor="#FF4F42"/>
        </linearGradient>
        <linearGradient id="paint3_linear_3712_38484" x1="9.29458e-05" y1="23.8098" x2="29.8339" y2="23.8098" gradientUnits="userSpaceOnUse">
        <stop stopColor="#02C6D6"/>
        <stop offset="0.99" stopColor="#FF257D"/>
        <stop offset="1" stopColor="#FF257D"/>
        </linearGradient>
        <clipPath id="clip0_3712_38484">
        <rect width="180" height="33.4837" fill={fill}/>
        </clipPath>
        </defs>
      </svg>
    </Icon>
  );
};